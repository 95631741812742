import { Log } from '@lightningjs/sdk'

import {
  findCoreSDKPlayerComponent,
  findLinkSelectableComponent,
  setPreCheckData,
} from '../../../store/PlayerStore/actions'
import { openErrorModal } from '../../../widgets/Modals/errorModal/ErrorModal'
import { StreamAuthorizationType, StreamRequest } from '../../../pages/Player/StreamLoader/request'
import { useRouterBackDisabled } from '../../../widgets/Modals/activation/hooks/useRouterBackDisabled'
import { StreamLoader } from '../../../pages/Player/StreamLoader'
import { CALL_SIGNS, CONTENT_TYPE, ROUTE } from '../../../constants'
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore'
import { BffPage } from '../../../graphql/mappers/bffPage'
import { SectionFragment } from '../../../graphql/generated/types'
import { getBffDataFromPid } from '../../../helpers'
import { useRequest } from '../../../lib/useRequest'
import { LiveStreamManager } from '../../../lib/LiveStreamManager'
import { StreamRequestConfig } from '../../../helpers/request'
import { EpgGuideController } from '../../EpgGuideV2/EpgGuideController'

const isBffPage = (data: any): data is BffPage => data && 'sections' in data

export const preCheckByCallSign = async (callSign: CALL_SIGNS, routerBackDisabled = false) => {
  try {
    const data = await useRequest(
      StreamRequestConfig(callSign, LiveStreamManager.isGlobalNavigation)
    ).fetch()
    await preCheck(data, routerBackDisabled)
    await savePreCheckData(data)
    return data
  } catch (error) {
    Log.error('Pre-check error', error)
    throw error
  }
}

const savePreCheckData = async (data: any) => {
  if (data && isBffPage(data)) {
    await PlayerStoreSingleton.dispatch(setPreCheckData({ data }))
    if (data.sections && data.sections.length > 0) {
      EpgGuideController.setTabs(findLinkSelectableComponent(data.sections as SectionFragment[]))
    }
  }
}

export const preCheckByPid = async (pid: string, routerBackDisabled = false) => {
  const data = await getBffDataFromPid(pid)
  const request = await preCheck(data, routerBackDisabled)
  await savePreCheckData(data)
  return request
}

const preCheck = async (data: any, routerBackDisabled: boolean) => {
  const coreSdkComponent = findCoreSDKPlayerComponent((data as any)?.sections)
  useRouterBackDisabled().set(routerBackDisabled)
  if (!coreSdkComponent) {
    openErrorModal({ authType: StreamAuthorizationType.MVPD }, {}, {})
    throw undefined
  }
  const program = coreSdkComponent.analytics
  const stream = coreSdkComponent.CoreSDKPlayerData.player
  const isLinear = stream.contentType === CONTENT_TYPE.LINEAR
  return await StreamLoader(stream, program, {}, false, true).catch((res: StreamRequest) => {
    if (!res.error?.handled) openErrorModal(res, stream, program, isLinear)
    throw res
  })
}

export const preCheckRoutes = [ROUTE.live, ROUTE.watch, ROUTE.liveWithParams]
