import Cta from '../Cta'

export default class Collection {
  _index: any
  _viewAllCta: any
  obj: any
  constructor(obj = {}, index = 0) {
    this.obj = obj
    this._index = index
    if (this.mapItems) {
      this.obj.items = this.mapItems(this.obj.items)
    }

    if (this.obj.viewAllCta) {
      this._viewAllCta = new Cta(this.obj.viewAllCta, this.obj.listTitle)
    }
  }

  mapItems(items: any) {
    return items
  }

  get component() {
    return this.obj.component
  }

  get shelfData() {
    return {
      playlistMachineName: this.playlistMachineName,
      listTitle: this.listTitle,
      shelfIndex: this.index,
      machineName: this.machineName,
    }
  }

  get initialItemHandle() {
    return this.obj.initialItemHandle
  }

  get initiallySelected() {
    return this.obj.initiallySelected
  }

  get viewAllCta() {
    return this._viewAllCta
  }

  get playlistMachineName() {
    return this.obj.playlistMachineName
  }

  get listTitle() {
    return this.obj.listTitle
  }

  get machineName() {
    return this.obj.machineName
  }

  get index() {
    return this._index
  }

  get instanceID() {
    return this.obj.instanceID
  }

  get items() {
    return this.obj.items
  }

  get moreItems() {
    return this.obj.moreItems
  }

  get lastModified() {
    return this.obj.lastModified
  }

  get ariaLabel() {
    return this.obj.ariaLabel
  }
}
