import { createItems } from '../../components/componentMaps'
import Container from '../models/Container'
import { useRequest } from '../../lib/useRequest'
import { PaginatedComponentRequestConfig } from '../../components/LinksSelectableGroup/request'

export default class ContinuousScroll {
  _continuousScroll: any
  _urlAlias: any
  constructor(continuousScroll: any, urlAlias: any) {
    this._continuousScroll = continuousScroll
    this._urlAlias = urlAlias
  }

  async getMoreItems(queryVariables: any) {
    const moreItemsData = await useRequest(PaginatedComponentRequestConfig(queryVariables)).fetch()
    const { data = {} } = new Container(moreItemsData?.data)

    return {
      items: createItems(data?.items),
      next: data?.next,
      previous: data?.previous,
      groupIds: data?.groupIds,
    }
  }

  get initiallySelected() {
    return this._continuousScroll.data.initiallySelected || 0
  }

  get initialItemHandle() {
    return this._continuousScroll.data.initialItemHandle
  }
}
