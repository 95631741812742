import { SecondaryControlButton } from './SecondaryControlButton'
import { COLORS, FONT_FACE } from '../../../../../../constants'
import { Lightning } from '@lightningjs/sdk'

export class SecondaryControlDynamicButton extends SecondaryControlButton {
  _label: string
  _isExpanded = false
  _isFocused = false

  static override _template() {
    return {
      Shader: {
        rect: true,
      },
      Label: {
        mountY: 0.5,
        text: {
          fontSize: 30,
          text: '',
          textColor: COLORS.white,
          fontFace: FONT_FACE.regular,
        },
      },
      Icon: { mountY: 0.5, x: 0, y: 0 },
    }
  }

  set label(_label) {
    this._label = _label
  }

  get label() {
    return this._label
  }

  expand() {
    this._isExpanded = true
    this.tag('Label').patch({
      text: { text: this.label },
    })
    this.stage.update()
    this._redraw()
  }

  collapse() {
    this._isExpanded = false
    this.tag('Label').patch({
      text: { text: '' },
    })
    this.stage.update()
    this._redraw()
  }

  override _redraw() {
    // button label width plus gap between icon and label
    const xGap = 10
    const labelWidth = this._isExpanded ? this.tag('Label').renderWidth + xGap : 0

    // button width
    const xButtonPadding = this._isExpanded ? 25 : 15
    const iconWidth = this.tag('Icon').renderWidth
    const totalWidth = iconWidth + labelWidth + xButtonPadding * 2

    // button height
    const yButtonPadding = this._isExpanded ? 5 : 10
    const height = this.tag('Label').renderWidth
      ? this.tag('Label').renderHeight + yButtonPadding
      : totalWidth - 3 // looks better with height just a few pixels < width

    const middleY = height / 2

    this.setSmooth('w', totalWidth)
    this.patch({
      h: height,
      Shader: {
        w: totalWidth,
        h: height,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: middleY,
          fillColor: this._isFocused ? COLORS.white : this._bgColor,
        },
      },
      Label: {
        x: iconWidth + xButtonPadding + xGap,
        y: middleY + 2,
        color: this._isFocused ? COLORS.dark : COLORS.white,
      },
      Icon: {
        x: xButtonPadding,
        y: middleY + 1,
        color: this._isFocused ? COLORS.dark : COLORS.white,
      },
    })
  }

  override _focus() {
    this._isFocused = true
    this.tag('Label').patch({
      text: { text: this.label },
    })
    this.stage.update()
  }

  override _unfocus() {
    this._isFocused = false

    this.stage.update()
    this._redraw()
  }
}
