import { Language, Lightning, Storage, Utils } from '@lightningjs/sdk'

import { EVENTS } from '../../../lib/analytics/types'
import { sendMetric } from '../../../lib/analytics/Analytics'
import Benefit from '../../Benefit'
import LogoRow from '../../LogoRow'
import TextButton from '../../../components/buttons/TextButton'

import {
  ALIGN_ITEMS,
  ALIGN_SELF,
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  JUSTIFY_CONTENT,
  LANGUAGES,
  SCREEN_SIZE,
  STORAGE_KEYS,
  TEXT_ALIGN,
} from '../../../constants'
import { FastImg, getBrandName, topLogoPathMapper } from '../../../helpers'
import ModalManager, { ModalTypes } from '../../../lib/ModalManager'
import LaunchDarklySingleton from '../../../lib/launchDarkly/LaunchDarkly'
import LaunchDarklyFeatureFlags from '../../../lib/launchDarkly/LaunchDarklyFeatureFlags'

enum STATES {
  activation = 'Activation',
  cancel = 'Cancel',
}
export default class OnboardingModal extends Lightning.Component {
  static override _template() {
    const brand = getBrandName()
    return {
      rect: true,
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      color: COLORS.dark,
      flex: {
        direction: FLEX_DIRECTION.row,
        justifyContent: JUSTIFY_CONTENT.center,
      },
      Background: {
        flexItem: false,
        w: SCREEN_SIZE.width,
        h: SCREEN_SIZE.height,
        src: Utils.asset(`images/onboarding/bg-${brand}.png`),
      },
      Content: {
        flex: {
          direction: FLEX_DIRECTION.column,
          alignItems: ALIGN_ITEMS.center,
        },
        flexItem: {
          alignSelf: ALIGN_SELF.center,
        },
        w: 936,
        h: 776,
        LogoWrapper: {
          w: 100,
          h: 100,
          flex: {
            direction: FLEX_DIRECTION.column,
            justifyContent: JUSTIFY_CONTENT.center,
          },
          Image: {
            flexItem: {
              alignSelf: ALIGN_SELF.center,
            },
            texture: FastImg(topLogoPathMapper(brand)).contain(100, 100),
          },
        },
        Title: {
          flexItem: {
            marginTop: 15,
          },
          text: {
            text: Language.translate('onboarding_title'),
            fontSize: 54,
            lineHeight: 70,
            textColor: COLORS.white5,
            textAlign: TEXT_ALIGN.center,
            fontFace: FONT_FACE.light,
            letterSpacing: -0.25,
          },
        },
        Benefits: {
          h: 276,
          flex: {
            direction: FLEX_DIRECTION.column,
          },
          flexItem: {
            marginTop: 15,
          },
          Item1: {
            type: Benefit,
            image: Utils.asset('images/watch-credits.png'),
            title: ['onboarding-benefit-1_line1', 'onboarding-benefit-1_line2'].map(
              Language.translate
            ),
          },
          Item2: {
            flexItem: {
              marginTop: 24,
            },
            type: Benefit,
            image: Utils.asset('images/cross-platform.png'),
            title: Language.translate('onboarding-benefit-2'),
          },
          Item3: {
            flexItem: {
              marginTop: 24,
            },
            type: Benefit,
            image: Utils.asset('images/vote.png'),
            title: Language.translate('onboarding-benefit-3'),
          },
        },
        ActivationButton: {
          w: 420,
          h: 50,
          flexItem: {
            marginTop: 58,
          },
          type: TextButton,
          radius: 26,
          fontSize: 26,
          fontFace: FONT_FACE.regular,
          focusFontColor: COLORS.black,
          unfocusFontColor: COLORS.warmGray2,
          focusBackGroundColor: COLORS.white,
          unfocusBackgroundColor: COLORS.transparent,
          strokeWidth: 2,
          strokeColor: COLORS.warmGray2,
          padding: 34,
          label: Language.translate('onboarding-sign-in-or-sign-up').toUpperCase(),
        },
        CancelButton: {
          w: 420,
          h: 50,
          flexItem: {
            marginTop: 16,
          },
          type: TextButton,
          radius: 26,
          fontSize: 26,
          fontFace: FONT_FACE.regular,
          focusFontColor: COLORS.black,
          unfocusFontColor: COLORS.warmGray2,
          focusBackGroundColor: COLORS.white,
          unfocusBackgroundColor: COLORS.transparent,
          strokeWidth: 2,
          strokeColor: COLORS.warmGray2,
          padding: 34,
          label: Language.translate('no-thanks').toUpperCase(),
        },
        LogoRow: {
          flexItem: {
            marginTop: 61,
          },
          type: LogoRow,
        },
      },
    }
  }

  get announceContext(): string[] {
    const title = this.tag('Title').text.text
    const benefits = this.tag('Benefits').children.map((child: any) => child.title)
    return [title, ...benefits]
  }

  override _init() {
    this._setState(STATES.activation)
  }

  override _handleKey(): boolean {
    return true
  }

  override _active() {
    const onboardingCtaFlag = LaunchDarklySingleton.getFeatureFlag(
      LaunchDarklyFeatureFlags.onboardingCta
    )
    const isSpanish = Language.get() === LANGUAGES.SPANISH

    if (onboardingCtaFlag?.body?.signInButton) {
      this.tag('ActivationButton')?.patch({
        label: isSpanish
          ? onboardingCtaFlag.body.signInButton?.es
          : onboardingCtaFlag.body.signInButton?.en,
      })
    }

    if (onboardingCtaFlag?.body?.skipButton) {
      this.tag('CancelButton')?.patch({
        label: isSpanish
          ? onboardingCtaFlag.body.skipButton?.es
          : onboardingCtaFlag.body.skipButton?.en,
      })
    }

    sendMetric(EVENTS.MODAL_LOAD, {
      modalName: 'Onboarding Modal',
      modalType: 'Onboarding',
      firstVisit: 'True',
    })
  }

  override _inactive() {
    Storage.set(STORAGE_KEYS.ONBOARDING_WAS_SHOWN, true)
  }

  override _handleBack(): void {
    ModalManager.close()
  }

  static override _states() {
    return [
      class Activation extends this {
        override _getFocused() {
          return this.tag('ActivationButton')
        }
        override _handleDown(): void {
          this._setState(STATES.cancel)
        }
        override _handleEnter(): void {
          const onboardingCtaFlag = LaunchDarklySingleton.getFeatureFlag(
            LaunchDarklyFeatureFlags.onboardingCta
          )
          const isSpanish = Language.get() === LANGUAGES.SPANISH

          if (onboardingCtaFlag?.body?.onboarding) {
            sendMetric(EVENTS.CLICK, {
              name: isSpanish
                ? onboardingCtaFlag.body?.signInButton?.es
                : onboardingCtaFlag.body?.signInButton?.en,
            })
          } else {
            sendMetric(EVENTS.CLICK, {
              name: 'Sign in or Sign up',
            })
          }

          ModalManager.open(ModalTypes.ONBOARDING_ACTIVATION, {}, false)
        }
      },
      class Cancel extends this {
        override _getFocused() {
          return this.tag('CancelButton')
        }
        override _handleUp(): void {
          this._setState(STATES.activation)
        }
        override _handleEnter(): void {
          const onboardingCtaFlag = LaunchDarklySingleton.getFeatureFlag(
            LaunchDarklyFeatureFlags.onboardingCta
          )
          const isSpanish = Language.get() === LANGUAGES.SPANISH

          if (onboardingCtaFlag?.body?.onboarding) {
            sendMetric(EVENTS.CLICK, {
              name: isSpanish
                ? onboardingCtaFlag.body?.skipButton?.es
                : onboardingCtaFlag.body?.skipButton?.en,
            })
          } else {
            sendMetric(EVENTS.CLICK, {
              name: 'No thanks',
            })
          }

          ModalManager.close()
        }
      },
    ]
  }
}
