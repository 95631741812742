import { getUserProfile } from '../../../../api/Identity'
import AuthenticationSingleton from '../../../../authentication/Authentication'
import { ROUTE, ROUTE_ENTITY, SLE_STATUS } from '../../../../constants'
import { getMvpd } from '../../../../helpers'
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore'
import { StreamAuthorizationType, StreamRequest } from '../request'
import {
  missingDataError,
  noCreditsError,
  parentalControlsError,
  unauthenticatedError,
  unauthorizedError,
} from '../error'
import {
  getLemonade,
  setLemonade,
  setPreCheckLemonade,
} from '../../../../store/PlayerStore/actions/lemonade'
import { isEmpty } from 'lodash'
import RouterUtil from '../../../../util/RouterUtil'

/**
 * Formats the rating so it can be used by Adobe
 * Refer to https://experienceleague.adobe.com/en/docs/pass/authentication/auth-features/user-metadat/user-metadata-feature for rating examples
 */
const formatRating = (rating?: string) => {
  if (!rating) return 'TV-Y'
  // Clear advisories data and cast to uppercase
  rating = rating?.match(/^\S*/)?.[0]?.toUpperCase()
  // If rating comes as i.e.: TV14, instead of TV-14, add dash
  rating = rating?.includes('TV') && !rating?.includes('-') ? rating?.replace('TV', 'TV-') : rating
  return rating
}

const authorize = async (request: StreamRequest, stream: any, program: any) => {
  const channelId = stream?.channelId?.toLowerCase?.() || ''
  const contentType = stream?.contentType || stream?.pid ? 'sle' : 'linear'
  const asset = {
    title: program?.programTitle || program?.title,
    mpxGuid:
      program?.mpxGuid || program?.nbcuId || program?.tmsId || program?.brandV4ID || stream?.pid,
    rating: formatRating(
      program?.rating || program?.ratingWithAdvisories || program?.ratingAdvisories?.[0]
    ),
    resourceId: program?.resourceId || stream?.resourceId,
    league: program?.league || stream?.league,
  }

  request.token = await AuthenticationSingleton.authorize(asset)

  if (!request.token) {
    request.error = unauthenticatedError({})
    throw request
  }
  if (request.token?.status === 403) {
    request.error = unauthorizedError(request.token)
    throw request
  }

  const jwt = await AuthenticationSingleton.verifyMediaToken(
    request.token,
    channelId,
    contentType
  )
  if (!jwt?.valid) {
    request.error = unauthenticatedError({})
    throw request
  }
  return request
}

export const linearSleFerAuthorize = async (
  request: StreamRequest,
  stream: any,
  program: any,
  lemonade: any,
  isPreCheck: boolean
) => {
  if (request.authType !== StreamAuthorizationType.UNLOCKED)
    await authorize(request, stream, program)

  if (isEmpty(lemonade)) {
    try {
      const lemonade = await getLemonade(stream)()
      if (lemonade?.payload?.status === SLE_STATUS.EXPIRED || lemonade?.payload?.errors) {
        throw request
      }
      if (isPreCheck) await PlayerStoreSingleton.dispatch(setPreCheckLemonade(lemonade?.payload))
      else await PlayerStoreSingleton.dispatch(setLemonade(lemonade?.payload))
    } catch {
      request.error = missingDataError('Expired asset')
      throw request
    }
  }

  return request
}

export const vodAuthorize =
  (bypass: boolean) => async (request: StreamRequest, stream: any, program: any) => {
    if (!program?.locked) {
      request.authType = StreamAuthorizationType.UNLOCKED
      return request
    }
    try {
      await authorize(request, stream, program)
    } catch {
      const userProfile = getUserProfile()
      const mvpd = getMvpd()

      if (request?.token?.message === 'noAuthz') throw request

      if (!!mvpd && request?.token?.details?.toLowerCase?.().search('parent') > -1) {
        request.error = parentalControlsError({})
        throw request
      } else if (!userProfile) {
        request.error = unauthenticatedError({})
        throw request
      } else if (
        !bypass &&
        !userProfile.isVideoAlreadyPlayed(program?.mpxGuid) &&
        userProfile.episodeCount > 0
      ) {
        RouterUtil.navigateToRoute(ROUTE.credits, {
          entity: ROUTE_ENTITY.videoId,
          value: program?.mpxGuid,
        })
        request.authType = StreamAuthorizationType.SHOW_CREDITS
        return request
      } else if (userProfile.isVideoAlreadyPlayed(program?.mpxGuid) || bypass) {
        request.authType = StreamAuthorizationType.CREDIT
        return request
      } else {
        request.error = noCreditsError(program?.mpxGuid)
        throw request
      }
    }
    return request
  }
