import BaseEventsPlayer from './BaseEventsPlayer'

import FERPlayerControls from '../../components/player/PlayerControls/FERPlayerControls'

import { PlayerStates } from '../../constants'
import { CONTENT_TYPE, PAGE_NAME } from '../../constants'
import { AdBreakDataEvent } from '../../player/model/event/AdBreakDataEvent'
import { Router } from '@lightningjs/sdk'
import { WithSeeking } from './hoc/WithSeeking'
import { WithPauseAds } from './hoc/WithPauseAds'
import { FERAnalyticsDelegate } from './delegates/analytics/FERAnalyticsDelegate'
import { WithEndCardFER } from './hoc/WithEndCard/WithEndCardFER'
import { WithTempPass } from './hoc/WithTempPass'
import { useProgress } from '../../components/player/PlayerControls/hooks/useProgress'
import TVPlatform from '../../lib/tv-platform'

class FERPlayer extends BaseEventsPlayer {
  override _log_tag = 'FER Player'
  override _stream_type = CONTENT_TYPE.FER
  override _controls: FERPlayerControls
  override _params: any
  _progress = useProgress()

  static override _template() {
    return super._template({
      Controls: { type: FERPlayerControls },
    })
  }

  override set params(params: any) {
    this._params = params
    this.pid = params?.pid
  }

  override _init() {
    super._init()
    this._analyticsDelegate = new FERAnalyticsDelegate(this)
  }

  override _playerEventsHandler(event: any) {
    super._playerEventsHandler(event)
    if (event instanceof AdBreakDataEvent) {
      this._controls.addAdBreakPoints(event.adBreaks.filter(({ startTime }: any) => startTime > 0))
    }
  }

  override _detach() {
    super._detach()
  }
  override _setPlayerInActiveTimeout() {}

  override _onStreamEnd() {
    // Don't navigate back if FER endcard is displayed
    if (this._getState() === PlayerStates.PlayerEndcardFER) return
    Router.back()
  }
}

export default class extends WithPauseAds(WithSeeking(WithEndCardFER(WithTempPass(FERPlayer)))) {}
