import { Language } from '@lightningjs/sdk'
import get from 'lodash/get'

import getPageAttributes, { getReferrer } from './getPageAttributes'
import {
  getAppLaunchType,
  getMvpdValue,
  getProfileValue,
  PLATFORM_TYPES,
} from '../../../../helpers'
import { getPlatformName, getProduct, getDeviceModel, getMpid } from '../../../../helpers'
import { setDefaultValue } from '../../../../helpers'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'
import ModalManager, { ModalTypes } from '../../../ModalManager'

const PLATFORM_NAME = {
  [PLATFORM_TYPES.XBOX]: 'xbox',
  [PLATFORM_TYPES.XBOXDEV]: 'xboxdev',
  [PLATFORM_TYPES.VIZIO]: 'vizio',
  [PLATFORM_TYPES.TIZEN]: 'tizen',
  [PLATFORM_TYPES.XCLASS]: 'XClass',
  [PLATFORM_TYPES.KEPLER]: 'FireTV',
}

const OS_NAME = {
  [PLATFORM_TYPES.XBOX]: 'xbox',
  [PLATFORM_TYPES.XBOXDEV]: 'xboxdev',
  [PLATFORM_TYPES.VIZIO]: 'vizio',
  [PLATFORM_TYPES.TIZEN]: 'tizen',
  [PLATFORM_TYPES.XCLASS]: 'XClass',
  [PLATFORM_TYPES.KEPLER]: 'Kepler',
}

const getGlobalAttributes = (params = {}) => {
  // @ts-expect-error TS(2339): Property 'path' does not exist on type '{}'.
  const { path: currentPath, pageName, launchType, firstVisit, league } = params

  const getPlatform = () => {
    const platformName = getPlatformName()
    return PLATFORM_NAME[platformName] || ''
  }

  const getOS = () => {
    const platformName = getPlatformName()
    return OS_NAME[platformName] || ''
  }

  const path =
    !ModalManager._activeModal || ModalManager._activeModal === ModalTypes.PLAYER_LOADER
      ? currentPath
      : ModalManager._activeModal

  const page = get(pageName, 'current') || getPageAttributes(path) || {}

  return setDefaultValue(
    {
      'Ad Audience': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Ad Blocker': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Ad Experiment': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Ad Tracking Opt-Out': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Ad Variant': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Branch Campaign': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Branch Channel': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Branch Feature': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Branch mParticle ID': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Branch Tag': MPARTICLE_DEFAULT_ATTR_VALUE,
      'Device Model': getDeviceModel(),
      Experiment: MPARTICLE_DEFAULT_ATTR_VALUE,
      'First Visit': firstVisit,
      Language: Language.get(),
      League: league,
      mParticleSessionId: getMpid(),
      MVPD: getMvpdValue(),
      'Open Type': launchType || getAppLaunchType(),
      Orientation: MPARTICLE_DEFAULT_ATTR_VALUE,
      OS: getOS(),
      'Page Name': page.name,
      'Page Type': page.type,
      'Peacock Account Tier': MPARTICLE_DEFAULT_ATTR_VALUE,
      Platform: getPlatform(),
      Product: getProduct(),
      Profile: getProfileValue(),
      'Push Opt-Out': MPARTICLE_DEFAULT_ATTR_VALUE,
      'QR Code': 'False',
      'Referring Page': getReferrer(),
      SEO: MPARTICLE_DEFAULT_ATTR_VALUE,
      'UTM Campaign': MPARTICLE_DEFAULT_ATTR_VALUE,
      'UTM Content': MPARTICLE_DEFAULT_ATTR_VALUE,
      'UTM Medium': MPARTICLE_DEFAULT_ATTR_VALUE,
      'UTM Source': MPARTICLE_DEFAULT_ATTR_VALUE,
      'UTM Term': MPARTICLE_DEFAULT_ATTR_VALUE,
      Variant: MPARTICLE_DEFAULT_ATTR_VALUE,
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )
}

export default getGlobalAttributes
