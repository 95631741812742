import BaseCollection from './BaseCollection'
import { ShimmerLoader } from './index'
import { setSmooth } from '../helpers'
import { ScrollElementType } from '../util/contentPosition'
import { COMPONENT_TYPES, FONT_FACE, SHIMMER_LOADER_TILES } from '../constants'
import { SectionsSpawner } from '../api/spawners'
import { Subscription } from 'rxjs'
import { useRequest } from '../lib/useRequest'
import {
  ComponentsForPlaceholdersConfig,
  MixedEditorialConfig,
  onAirNowShelfConfig,
} from '../helpers/request'
import TVPlatform from '../lib/tv-platform'
import { ErrorType } from '../lib/tv-platform/types'

export enum PlaceholderReferrer {
  PLACEHOLDER,
  MIX_EDITORIAL,
  ON_AIR,
}

export default class Placeholder extends BaseCollection {
  _noOfItemsToScroll: any
  _subscription: Subscription | null
  _referrer = PlaceholderReferrer.PLACEHOLDER
  _queryVariables: any
  protected _initiallySelected = 0
  protected _initialItemHandle?: string

  static override _template() {
    return {
      TitleHolder: {
        Title: {
          alpha: 0.87,
          text: { fontFace: FONT_FACE.light, fontSize: 40 },
        },
      },
      Items: {
        y: 120,
        forceZIndexContext: true,
        boundsMargin: [500, 100, 500, 100],
      },
    }
  }

  override _init() {
    this._index = 0
    /** Initialize the placeholder section with empty tiles until data items are set */
    this.tag('Items').childList.a(this.createEmptyLoaderTiles())
  }

  set initiallySelected(initiallySelected: number) {
    this._initiallySelected = initiallySelected
  }

  set initialItemHandle(initialItemHandle: string) {
    if (!initialItemHandle) return
    this._initialItemHandle = initialItemHandle
  }

  set queryVariables(v: any) {
    this._queryVariables = v
  }

  override _detach() {
    super._detach()
    this._subscription?.unsubscribe()
  }

  override async _enable() {
    super._enable()
    if (!this._queryVariables) return
    try {
      const request = (() => {
        switch (this._referrer) {
          case PlaceholderReferrer.ON_AIR:
            return useRequest(onAirNowShelfConfig(this._queryVariables))
          case PlaceholderReferrer.MIX_EDITORIAL:
            return useRequest(MixedEditorialConfig(this._queryVariables))
          default:
            return useRequest(ComponentsForPlaceholdersConfig(this._queryVariables))
        }
      })()
      const response = await request.fetch()
      this.setPlaceholderData({
        data: response && 'components' in response ? response?.components : [response?.data],
      })
    } catch (e) {
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        description: 'Placeholder Request Error',
        payload: e,
      })
    }
  }

  set referrer(v: PlaceholderReferrer) {
    this._referrer = v
  }

  createEmptyLoaderTiles() {
    const style = this.getStyle()
    if (!style) {
      console.warn('Placeholder.js missing style. Check ./src/lib/style.js')
      return
    }
    this.noOfItemsToScroll = style.noOfListItemsToScroll

    const emptyLoaderItems = []

    for (let i = 0; i <= SHIMMER_LOADER_TILES; i++) {
      emptyLoaderItems.push({
        type: ShimmerLoader,
        itemType: 'videoTile',
        item: { w: 420, h: 235 },
        index: i,
        x: i * style.item.w,
        y: 70,
      })
    }
    return emptyLoaderItems
  }

  override _handleBack(e: any) {
    if (this._index === 0) {
      return false
    } else {
      this.setIndex(0)
      e.preventDefault()
      e.stopPropagation()
    }
  }

  setIndex(index: any) {
    this._index = index
    const style = this.getStyle()
    const noOfItems = this.noOfItemsToScroll || 4

    setSmooth(
      this.tag('Items'),
      'x',
      index > noOfItems - 1 ? (index - (noOfItems - 1)) * -style.item.w : 0,
      {
        duration: 0.3,
        delay: 0,
      }
    )

    this.fireAncestors('$scrolledListItemIndex', { index })
    this._refocus()
  }

  override _getFocused() {
    return this.activeItemWrapper
  }

  async setPlaceholderData(v: any) {
    if (!v?.data?.length) {
      this.fireAncestors('$onEmptyWrapperList', this)
      return
    }
    const data = v.data.map((_section: any) => {
      if (_section?.component === COMPONENT_TYPES.GROUPED_CONTINUOUS_SCROLL) {
        _section = { ..._section }
        _section.data.initiallySelected = this._initiallySelected
        _section.data.initialItemHandle = this._initialItemHandle
      }
      return _section
    })
    const sections = await SectionsSpawner(this.stage, data)
    if (sections?.length || 0 > 0) {
      this.fireAncestors('$onPlaceholderShelfChange', this, sections)
    } else {
      this.fireAncestors('$onEmptyWrapperList', this)
    }
  }

  override set items(v: any) {
    this.tag('Items').childList.clear()
    this.stage.gc()
    this._items = v
    const itemsToRender = this.create({ items: this._items })

    this.tag('Items').patch({
      children: itemsToRender,
    })
  }

  _removeItem(itemToRemove: any) {
    this.tag('Items').childList.remove(itemToRemove)
    if (this.tag('Items').childList.length === 0) {
      this.fireAncestors('$onEmptyWrapperList', this)
    }

    this._repositionItems()
    this.setIndex(0)
  }

  _repositionItems() {
    const style = this.getStyle()
    this.tag('Items').childList.forEach((item: any, index: any) => {
      item.patch({
        x: index * style.item.w,
      })
    })
  }

  set title(v: any) {
    if (v) {
      this.tag('Title').text = v
    } else {
      this.tag('Items').patch({
        y: 0,
      })
    }
  }

  set noOfItemsToScroll(noOfItems) {
    this._noOfItemsToScroll = noOfItems
  }

  get noOfItemsToScroll() {
    return this._noOfItemsToScroll || 4
  }

  $getCurrentScrollPosition() {
    const position = this.fireAncestors('$getCurrentScrollPosition')
    return {
      type: ScrollElementType.LIST,
      row: position.row || 0,
      instanceID: position.instanceID,
    }
  }
}
