import { Storage } from '@lightningjs/sdk'

import { MPARTICLE_ERROR_CONTEXTS, ROUTE, STORAGE_KEYS } from '../../../../constants'
import { getAdjustedReferringShelf } from '../../../../helpers'
import { EVENTS } from '../../../../lib/analytics/types'
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore'
import { AnalyticsWithEndCardDelegate } from './AnalyticsWithEndCardDelegate'
import { sendMetric } from '../../../../lib/analytics/Analytics'
import RouterUtil from '../../../../util/RouterUtil'
import AuthenticationSingleton from '../../../../authentication/Authentication'
import UserInteractionsStoreSingleton from '../../../../store/UserInteractions'
import { ClosedCaptionsUtils } from '../../../../lib/ClosedCaptions/ClosedCaptionsUtils'
import { isSingleProgram, isSingleStream } from '../../../../store/PlayerStore/actions'
import { useProgress } from '../../../../components/player/PlayerControls/hooks/useProgress'
import VODCorePlayer from '../../VODCorePlayer'

export class VODAnalyticsDelegate extends AnalyticsWithEndCardDelegate<VODCorePlayer> {
  _progress = useProgress()
  private _isSessionStarted = false

  override _getAnalyticsData() {
    const { program } = PlayerStoreSingleton
    if (!isSingleProgram(program)) return {}
    return {
      adobeBrand: program?.adobeBrand,
      adobeVideoResearchTitle: program?.adobeVideoResearchTitle,
      listOfGenres: program?.listOfGenres,
      secondaryGenre: program?.secondaryGenre,
      sport: program?.sport,
      league: program?.league,
      isOlympics: program?.isOlympics,
      entitlement: program?.entitlement,
      language: program?.language,
      durationInMilliseconds: program?.durationInMilliseconds,
      startTime: this._ctx._watchProgressDelegate?._previousLogTime || 0,
      resumeTime: this.resumeTime || 0,
      programTitle: program?.programTitle,
    }
  }

  get resumeTime() {
    const { stream } = PlayerStoreSingleton
    if (!isSingleStream(stream)) return 0
    const percentViewed = UserInteractionsStoreSingleton.getPercentViewed(stream?.v4ID)
    return percentViewed < 0.95 ? percentViewed * (stream?.duration || 0) : 0
  }

  override getErrorAnalytics(error: any, errorEvent: any) {
    const { stream } = PlayerStoreSingleton
    const mvpd = AuthenticationSingleton.getMvpdData()?.mvpd
    const code = errorEvent?.code || errorEvent?.type
    const description = errorEvent?.description || errorEvent?.details || error?.title
    return {
      video: {
        ...(stream ? stream : {}),
        analytics: this._getAnalyticsData(),
        currentTime: this._progress.value[0],
      },
      player: this._ctx._player,
      errorType: 'Video',
      description,
      friendlyMessage: error?.message?.replace('%{brand}', stream?.brandDisplayTitle),
      code,
      mvpd,
      source: MPARTICLE_ERROR_CONTEXTS.vod,
      system: 'Other',
      stack: errorEvent?.stack,
      fatal: true,
      isLive: false,
    }
  }

  override firePageLoad() {
    sendMetric(EVENTS.PAGE_LOAD, {
      ...PlayerStoreSingleton.program,
      regRef: RouterUtil.checkGetRegistrationReferrer(),
      path: ROUTE.video,
    })
  }

  override fireSessionInit() {
    // no op
  }

  override fireSessionStart(payload = {}) {
    sendMetric(EVENTS.VOD_SESSION_START, {
      ...this._getMetricsData(),
      ...payload,
      ccLanguage: ClosedCaptionsUtils.getCCType(),
    })
    this._isSessionStarted = true
  }

  override fireSessionEnd(payload = {}) {
    if (!this._isSessionStarted) return
    const [currentTime, duration] = this._progress.value
    sendMetric(EVENTS.VOD_SESSION_END, {
      ccLanguage: ClosedCaptionsUtils.getCCType(),
      duration,
      watched: currentTime,
      ...this._getMetricsData(),
      ...payload,
    })
    this._isSessionStarted = false
  }

  fireContentComplete() {
    // no op
  }

  override _getMetricsData() {
    return {
      ...PlayerStoreSingleton.stream,
      ...this._getAnalyticsData(),
      shelf: getAdjustedReferringShelf(),
      smartTile: Storage.get(STORAGE_KEYS.SMART_TILE),
      authType: this._ctx.authtype,
      tokenType: this._ctx.tokenType,
    }
  }
}
