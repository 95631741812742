import getAuthAttributes from '../attributes/getAuthAttributes'
import { setDefaultValue, getAuthSSO, getAssetAuthAnalytics } from '../../../../helpers'
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants'
import AuthenticationSingleton from '../../../../authentication/Authentication'

const getExpiration = () => {
  const expiration = AuthenticationSingleton.getMvpdData()?.expires
  if (!expiration) return 'None'
  return new Date(parseInt(String(expiration), 10)).toISOString()
}

const getMvpdAuthSuccessAttributes = (params: any = {}) =>
  setDefaultValue(
    {
      ...getAuthAttributes(params),
      ...getAssetAuthAnalytics(params.program),
      'Geo Zip': params?.live?.geoZip,
      'MVPD Expiration Date': getExpiration(),
      'Auth SSO': params.userProfile && getAuthSSO(params.userProfile),
      MVPD: AuthenticationSingleton.getMvpdDataMvpdId(),
    },
    MPARTICLE_DEFAULT_ATTR_VALUE
  )

export default getMvpdAuthSuccessAttributes
