import Collection from './Collection'
import componentTypes from '../../types/componentTypes'
import Tiles from '../tiles'
import { Container } from '../index'

import { BrandItemLabel } from '../../../graphql/generated/types'
export default class BrandSelectableGroup extends Collection {
  constructor(obj = {}) {
    super(obj)
    this.obj.brandItemLabels = this.mapBrandItemLabels(this.obj.brandItemLabels)
  }

  mapBrandItemLabels(items: (typeof BrandItemLabel)[]) {
    return (
      items
        .filter(
          (item: typeof BrandItemLabel | null): boolean =>
            // @ts-expect-error Property 'component' does not exist on type 'DocumentNode'.
            item != null && Object.values(componentTypes).indexOf(item.component) > -1
        )
        // @ts-expect-error Property 'component' does not exist on type 'DocumentNode'.
        .map((item) => new Tiles[item.component as keyof typeof Tiles](item))
    )
  }

  override mapItems(items: any) {
    return items
      .filter(
        (item: any) => item != null && Object.values(componentTypes).indexOf(item.component) > -1
      )
      .map((item: any) => new Container(item))
  }

  get brandItemLabels() {
    return this.obj.brandItemLabels
  }
}
