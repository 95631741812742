import { Router, Storage } from '@lightningjs/sdk'
import trimStart from 'lodash/trimStart'

import mParticleInterface from '../lib/analytics/mParticle/mParticleInterface'
import { getWhiteLabelBrand } from './env'
import {
  COMPONENT_TYPES,
  ENTITY_TYPES,
  MPARTICLE_CLICK_STATES,
  MPARTICLE_DEFAULT_ATTR_VALUE,
  PAGE_TYPE,
  PROGRAMMING_TYPES,
  SIGN_IN_TYPE,
  STORAGE_KEYS,
} from '../constants'
import { getCurrentPageType } from '../lib/analytics/mParticle/attributes/getPageAttributes'
import { getUnixDate } from './date'
import { AppLaunchTypes } from '../lib/analytics/types'
import PlayerStoreSingleton from '../store/PlayerStore/PlayerStore'
import { isSingleProgram, isSingleStream } from '../store/PlayerStore/actions'
import { getAdjustedReferringShelf } from './metadata'
import UserInteractionsStoreSingleton from '../store/UserInteractions'

export const getProduct = () => getWhiteLabelBrand().app

// The app was initially launched with a hardcoded mParticleId.
export const removeHardcodedMPID = () => {
  const hardcodedMpid = '5727672205073229480'
  const mpid = Storage.get(STORAGE_KEYS.MPARTICLE)
  if (mpid && mpid === hardcodedMpid) {
    // Reset users localStorage for this app.
    Storage.clear()
  }
}

export const setMpid = (mpid: string | null = null, trim = false) => {
  if (trim && mpid) mpid = trimStart(mpid, '-')
  if (!!mpid) Storage.set(STORAGE_KEYS.MPARTICLE, mpid)
  return mpid
}

export const getMpid = (trim = false, forceReset = false) => {
  // try to get from storage
  if (!forceReset) {
    const storedMpid = Storage.get(STORAGE_KEYS.MPARTICLE)
    if (storedMpid) {
      return trim ? trimStart(storedMpid, '-') : storedMpid
    }
  }

  const newMpid = mParticleInterface.getMpid()
  return setMpid(newMpid, trim)
}

/**
 * Check user profile object for sso id's
 * @param {Profile} Identity profile data
 * @return {string} SSO value, if any
 */
export const getAuthSSO = (userProfile = {}) => {
  // @ts-expect-error TS(2339): Property 'googleId' does not exist on type '{}'.
  if (userProfile.googleId) return 'Google SSO'
  // @ts-expect-error TS(2339): Property 'facebookId' does not exist on type '{}'.
  if (userProfile.facebookId) return 'Facebook SSO'
  // @ts-expect-error TS(2339): Property 'appleId' does not exist on type '{}'.
  if (userProfile.appleId) return 'Apple SSO'
  return ''
}

/**
 * Set default values for null and undefined analytics values
 * @param {object} Object of analytics key/values
 * @param {string} Default value
 * @return {object} Analytics params with null/undefined values set to defaultValue
 */
export const setDefaultValue = (params: any, defaultValue: any) => {
  const attributes = params
  for (const [key, value] of Object.entries(attributes)) {
    if (!value && value !== 0) attributes[key] = defaultValue
  }
  return attributes
}

export const getAnalyticsObjectByComponent = (item: any) => {
  switch (item?.component) {
    case COMPONENT_TYPES.UPCOMING_LIVE_SLIDE:
      return item?.analytics
    default:
      return { ...item?.ctaAnalytics, title: item?.title, contentType: item?.contentType }
  }
}

export const getTileState = (tile: any) =>
  (tile.isUpcoming && MPARTICLE_CLICK_STATES.UPCOMING) ||
  (tile.isLive && MPARTICLE_CLICK_STATES.LIVE) ||
  (tile.programmingType === PROGRAMMING_TYPES.FER && MPARTICLE_CLICK_STATES.FER) ||
  MPARTICLE_DEFAULT_ATTR_VALUE

export const getEndCardAnalytics = (recommendation: any) => {
  const { analytics, tile } = recommendation?.item || {}
  const entityType = tile?.obj?.component
  const isShow = entityType === ENTITY_TYPES.SERIES_TILE

  return {
    brand: analytics?.brand?.title,
    episodeTitle: analytics?.title,
    league: analytics?.destinationLeague,
    locked: analytics?.locked,
    genre: isShow ? '' : analytics?.genre,
    mpxGuid: analytics?.mpxGuid,
    recommendationType: analytics?.recommendationType,
    show: analytics?.series,
    sport: analytics?.destinationSport,
    videoType: analytics?.programmingType,
  }
}

export const setSLEDefaultParams = (params: any) => {
  return getCurrentPageType() === PAGE_TYPE.linearVideoPlayer
    ? Object.assign(params, {
      ['Page Type']: PAGE_TYPE.live,
      'Referring Page': 'Live Guide',
    })
    : params
}

export const setAppLaunchTime = () => {
  Storage.set(STORAGE_KEYS.APP_LOADED_TIME, getUnixDate())
}

export const getDurationSinceLaunch = () => {
  return getUnixDate() - Storage.get(STORAGE_KEYS.APP_LOADED_TIME)
}

const getShowName = (program: any) => {
  switch (program.programmingType) {
    case PROGRAMMING_TYPES.MOVIE:
      return program.movie
    default:
      return program.series
  }
}

export const getAssetAuthAnalytics = (program: any) => {
  if (!program) return {}
  return {
    Season: program.seasonNumber,
    Show: getShowName(program),
    Brand: program.brand?.title,
  }
}

export const getSignInType = (userProfile: any) => {
  switch (true) {
    case !!userProfile.googleId:
      return SIGN_IN_TYPE.google
    case !!userProfile.facebookId:
      return SIGN_IN_TYPE.facebook
    case !!userProfile.appleId:
      return SIGN_IN_TYPE.apple
    default:
      return SIGN_IN_TYPE.email
  }
}

/**
 * Set app launch type
 * @param type
 */
export const setAppLaunchType = (type: any) => {
  Storage.set(STORAGE_KEYS.APP_LAUNCH_TYPE, type)
}

/**
 * Get current app launch type
 * @returns {*|string}
 */
export const getAppLaunchType = () => {
  return Storage.get(STORAGE_KEYS.APP_LAUNCH_TYPE) || AppLaunchTypes.default
}

/**
 * Checks programming type for Olympics and returns that if true, otherwise returns default value
 */
export const getIsOlympics = (analytics: any) => {
  return analytics?.isOlympics || analytics?.programmingType === PROGRAMMING_TYPES.OLYMPICS
}

const getResumeTime = (): number => {
  const { stream } = PlayerStoreSingleton
  if (!stream || !isSingleStream(stream)) return 0
  const percentViewed = UserInteractionsStoreSingleton.getPercentViewed(stream?.v4ID)
  return percentViewed < 0.95 ? percentViewed * (stream?.duration || 0) : 0
}

export const getPageLoadAttributes = () => {
  const { program } = PlayerStoreSingleton
  if (!program || !isSingleProgram(program)) return {}

  const page = Router.getActivePage()
  return {
    ...program,
    adobeBrand: program.adobeBrand,
    adobeVideoResearchTitle: program.adobeVideoResearchTitle,
    listOfGenres: program.listOfGenres,
    secondaryGenre: program.secondaryGenre,
    sport: program.sport,
    league: program.league,
    isOlympics: program.isOlympics,
    entitlement: program.entitlement,
    language: program.language,
    durationInMilliseconds: program.durationInMilliseconds,
    resumeTime: getResumeTime(),
    programTitle: program.programTitle,
    shelf: getAdjustedReferringShelf(),
    smartTile: Storage.get(STORAGE_KEYS.SMART_TILE),
    authType: page && 'authtype' in page && page.authtype,
    tokenType: page && 'tokenType' in page && page.tokenType,
  }
}
