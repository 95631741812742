import { getAssetAuthAnalytics } from '../../../../helpers'
import getAuthAttributes from './getAuthAttributes'

const getIDMConversionAttributes = (params: any) => {
  return {
    ...getAuthAttributes(params),
    ...getAssetAuthAnalytics(params.program),
    'Sign In Type': 'OTT Device',
  }
}

export default getIDMConversionAttributes
