import { AssetMetadata, AssetType } from '@sky-uk-ott/core-video-sdk-js'
import { get } from 'lodash'

import nielsenSubbrands from '../../constants/nielsenSubbrands'
import { PROGRAMMING_TYPES, CONTENT_TYPE } from '../../constants'
import TVPlatform from '../../lib/tv-platform'
import MvpdData from '../../api/models/MvpdData'
import { LemonadeResponse } from '../../lib/lemonade/Lemonade'
import { Program, Stream } from '../../store/PlayerStore/PlayerStore'
import { isEpgProgram } from '../../store/PlayerStore/actions/epg'
import { isSingleProgram } from '../../store/PlayerStore/actions'
import { VideoAnalyticsEntitlement } from '../../graphql/generated/types'

const ADOBE_DEFAULT_VALUE_NONE = 'None'
const ADOBE_DEFAULT_VALUE_UNKNOWN = 'unknown'
const ADOBE_REQUESTOR_ID = 'nbcentertainment'

const getComscoreAssetMetadata = ({
  lemonade,
  program,
  stream,
}: {
  lemonade: LemonadeResponse
  program: Program
  stream: Stream
}) => {
  if (!program) return {}
  const epgProgram = isEpgProgram(program)
  const type =
    epgProgram &&
    {
      'Full Episode': AssetType.Episode,
      'Web Exclusive': AssetType.Clip,
      'Single Live Event': AssetType.Episode,
      Highlight: AssetType.Clip,
      Clip: AssetType.Clip,
      Trailer: AssetType.Clip,
      Movie: AssetType.Programme,
    }[program?.programmingType as string]

  return {
    availableAt: new Date((epgProgram ? program?.startTime : program?.airDate) || Date.now()),
    channelId:
      (lemonade.pid && `${lemonade.pid}`) ||
      (epgProgram && program?.channelId) ||
      stream?.channelId,
    id:
      lemonade?.adServerContentId ||
      program?.mpxGuid ||
      (epgProgram && program?.externalAdvertiserId) ||
      program?.tmsId ||
      lemonade.pid?.toString?.() ||
      'N/A', // VOD - mpx guid.
    seriesTitle: getSeriesTitleByProgrammingType(program),
    title: program?.programTitle || (!epgProgram && program?.title) || '',
    type: type || AssetType.Clip,
  }
}

const getConvivaAssetMetadata = ({
  lemonade,
  program,
  product,
  isNBCProfileLinked,
}: {
  lemonade: LemonadeResponse
  program: Program
  product: any
  isNBCProfileLinked?: boolean
}) => ({
  adProvider: 'EMT',
  brand: get(program, 'brand.title'),
  product,
  profile: isNBCProfileLinked ? 'Free' : 'Unauthenticated',
  videoId: lemonade.pid || program?.tmsId,
  isFromBackground: false,
  deviceId: TVPlatform.deviceId,
})

const getNielsenMetadata = ({ program, stream }: { program: Program; stream: Stream }) => {
  const singleProgram = isSingleProgram(program)
  const normalizedBrand = singleProgram ? program?.title?.toLowerCase().replace(' ', '') : ''
  const channelId = (!singleProgram && program?.channelId) || stream?.channelId
  return {
    // @ts- expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    subBrand:
      nielsenSubbrands[normalizedBrand as keyof typeof nielsenSubbrands] ||
      nielsenSubbrands[channelId as keyof typeof nielsenSubbrands] ||
      'c05',
  }
}

const getAdobeAssetMetadata = ({ program, stream }: { program: Program; stream: Stream }) => ({
  assetId: program?.tmsId || (program && 'pid' in program && program?.pid),
  genre: program?.genre,
  programmeStartEpoch:
    program && 'programStartTime' in program
      ? Math.floor(new Date(program?.programStartTime as string).getTime() / 1000)
      : Math.floor(Date.now() / 1000),
  league: program?.league || ADOBE_DEFAULT_VALUE_NONE,
  sport: program?.sport || ADOBE_DEFAULT_VALUE_NONE,
  videoClipType: program?.clipCategory, // Set this for Vod Clips only (Highlights, Sneak Peek, Interview, etc.)
  videoepnumber: ADOBE_DEFAULT_VALUE_UNKNOWN,
  videoLanguage: program && 'language' in program && program?.language,
  videoPassGuid: stream?.v4ID || ADOBE_DEFAULT_VALUE_UNKNOWN,
  videoRequestorId: program?.resourceId || ADOBE_REQUESTOR_ID || ADOBE_DEFAULT_VALUE_UNKNOWN,
  adobeVideoResearchTitle: program?.adobeVideoResearchTitle || ADOBE_DEFAULT_VALUE_UNKNOWN,
  dayPart: program?.dayPart,
})

const getSubGenre = (program: any) => {
  const genre =
    get(program, 'analytics.secondaryGenre') || program?.listOfGenres?.split(', ') || program?.genre
  return Array.isArray(genre) ? genre : genre ? [genre] : []
}

const getChannelName = ({ program, stream }: { program: Program; stream: Stream }) => {
  if (get(stream, 'contentType') === CONTENT_TYPE.SLE) return
  let channelName
  if (get(stream, 'contentType') === CONTENT_TYPE.LINEAR) {
    channelName = stream?.brandDisplayTitle
  } else {
    channelName = (program && 'channelId' in program && program?.channelId) || stream?.channelId
  }
  return { channelName }
}

export const getAssetMetadata = ({
  lemonade,
  program,
  mvpd,
  product,
  isNBCProfileLinked,
  stream,
  sessionId,
}: {
  lemonade: LemonadeResponse
  program: Program
  mvpd: MvpdData | null
  product: any
  isNBCProfileLinked?: boolean
  stream: Stream
  sessionId?: string
}): AssetMetadata => {
  return {
    ...getConvivaAssetMetadata({
      lemonade,
      program,
      product,
      isNBCProfileLinked,
    }),
    ...getComscoreAssetMetadata({ lemonade, program, stream }),
    ...getAdobeAssetMetadata({ program, stream }),
    ...getNielsenMetadata({ program, stream }),
    ...getChannelName({ program, stream }),
    appSessionId: sessionId,
    airedFirstAt: program?.airDate
      ? new Date(isNaN(Number(program.airDate)) ? program.airDate : Number(program.airDate))
      : new Date(),
    callSign: program?.callSign || undefined,
    // @ts-expect-error FIXME: According to the type definition this should be a ContentType enum
    contentType: lemonade?.pid
      ? program?.programmingType === 'Single Live Event'
        ? 'SLE'
        : 'FER'
      : 'Live',
    duration: program?.duration || 0,
    entitled: program?.locked || undefined,
    episodeNumber: program?.episodeNumber ? Number(program?.episodeNumber) : undefined,
    episodeTitle: program?.videoTitle || (program && 'title' in program && program?.title) || '',
    graceId: program?.tmsId || program?.titleTmsId || 'None',
    isExclusiveChannel: false,
    isVodChannel: false,
    programmeId: lemonade.pid?.toString?.() || program?.tmsId || '',
    programmeTitle: getProgrammeTitleByProgrammingType(program),
    providerName: 'NBC',
    seasonNumber: program?.seasonNumber ? Number(program?.seasonNumber) : undefined,
    studioName: 'N/A',
    status: getVideoStatus(program),
    subGenre: getSubGenre(program),
    title: getTitleByProgrammingType(program),
    videoMvpd: mvpd?.mvpd || 'Unauthenticated',
    videoNetworkName: get(program, 'brand.title'),
    videoTmsId: program?.tmsId || program?.titleTmsId || 'None',
    videoBroadcast: (program && 'videoBroadcast' in program && program?.videoBroadcast) || 'None',
  }
}

const getSeriesTitleByProgrammingType = (program: Program) => {
  const programTitle = program && 'title' in program ? program.title : ''
  switch (program?.programmingType) {
    case PROGRAMMING_TYPES.MOVIE:
      return program?.movie || programTitle || ''
    case PROGRAMMING_TYPES.FULL_EPISODE:
    case PROGRAMMING_TYPES.CLIP:
    case PROGRAMMING_TYPES.SNEAK_PEAK:
    case PROGRAMMING_TYPES.WEB_EXCLUSIVE:
    case PROGRAMMING_TYPES.EXCERPT:
    case PROGRAMMING_TYPES.HIGHLIGHT:
      return program?.series || program?.programTitle || programTitle || ''
    case PROGRAMMING_TYPES.SFVOD:
      return program?.league || program?.sport || programTitle || ''
    case PROGRAMMING_TYPES.FER:
    case PROGRAMMING_TYPES.SLE:
      return program?.programTitle || programTitle || ''
    default:
      return program?.programTitle || programTitle || ''
  }
}

const getTitleByProgrammingType = (program: Program) => {
  const programTitle = program && 'title' in program ? program.title : ''
  switch (program?.programmingType) {
    case PROGRAMMING_TYPES.MOVIE:
      return program?.movie || programTitle || ''
    case PROGRAMMING_TYPES.FULL_EPISODE:
    case PROGRAMMING_TYPES.CLIP:
    case PROGRAMMING_TYPES.SNEAK_PEAK:
    case PROGRAMMING_TYPES.WEB_EXCLUSIVE:
    case PROGRAMMING_TYPES.EXCERPT:
    case PROGRAMMING_TYPES.HIGHLIGHT:
      return programTitle || ''
    case PROGRAMMING_TYPES.SFVOD:
    case PROGRAMMING_TYPES.FER:
    case PROGRAMMING_TYPES.SLE:
      return program?.videoTitle || programTitle || ''
    default:
      return program?.videoTitle || programTitle || ''
  }
}

const getProgrammeTitleByProgrammingType = (program: Program) => {
  const programTitle = program && 'title' in program ? program.title : ''
  switch (program?.programmingType) {
    case PROGRAMMING_TYPES.MOVIE:
      return program?.movie || programTitle || ''
    case PROGRAMMING_TYPES.FULL_EPISODE:
    case PROGRAMMING_TYPES.CLIP:
    case PROGRAMMING_TYPES.SNEAK_PEAK:
    case PROGRAMMING_TYPES.WEB_EXCLUSIVE:
    case PROGRAMMING_TYPES.EXCERPT:
    case PROGRAMMING_TYPES.HIGHLIGHT:
      return program?.series || ''
    case PROGRAMMING_TYPES.SFVOD:
      return program?.programTitle || program?.videoTitle || programTitle || ''
    case PROGRAMMING_TYPES.FER:
    case PROGRAMMING_TYPES.SLE:
      return program?.videoTitle || program?.programTitle || programTitle || ''
    default:
      return program?.videoTitle || programTitle || ''
  }
}

enum VIDEO_STATUS {
  UNRESTRICTED = 'Unrestricted',
  PREVIEW = 'Preview',
  RESTRICTED = 'Restricted',
}

const getVideoStatus = (program: Program) => {
  if (program?.programmingType === PROGRAMMING_TYPES.PREVIEW) {
    return VIDEO_STATUS.PREVIEW
  } else if (program && 'entitlement' in program && program.entitlement) {
    if (program.entitlement === VideoAnalyticsEntitlement.Free) {
      return VIDEO_STATUS.UNRESTRICTED
    } else if (program.entitlement === VideoAnalyticsEntitlement.Entitled) {
      return VIDEO_STATUS.RESTRICTED
    }
  }
}
