import { Lightning, Router } from '@lightningjs/sdk'

import MenuItem from './MenuItem'

import { ALIGN_ITEMS, COLORS, FLEX_DIRECTION, FONT_FACE, ROUTE } from '../../constants'
import { LiveStreamManager } from '../../lib/LiveStreamManager'
import { sendMetric } from '../../lib/analytics/Analytics'
import { EVENTS } from '../../lib/analytics/types'
import ContentPosition from '../../util/contentPosition'

const DEFAULT_FOCUS_RADIUS = 5
const FOCUS_PADDING = 20
export default class ItemsBar extends Lightning.Component {
  _onRouteInfoChange: any
  _focusIndex = 0
  _activeIndex = 0
  initiallySelected = 0
  static override _template() {
    return {
      flex: {
        direction: FLEX_DIRECTION.row,
      },
      Focus: {
        flexItem: false,
        rtt: true,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: DEFAULT_FOCUS_RADIUS,
        },
        rect: true,
        color: COLORS.lightGray1,
        alpha: 0,
        h: 58,
      },
      Items: {
        rtt: true,
        flex: {
          direction: FLEX_DIRECTION.row,
          alignItems: ALIGN_ITEMS.center,
        },
        h: 58,
        children: [],
      },
      SubCatHolder: {
        visible: false,
        flex: {
          direction: FLEX_DIRECTION.row,
          alignItems: ALIGN_ITEMS.center,
        },
        Line: {
          rect: true,
          h: 45,
          w: 2,
          color: COLORS.lightGray5,
        },
        SubCat: {
          flexItem: {
            marginLeft: 20,
          },
        },
      },
    }
  }

  setFocusIndex(index: any) {
    this._focusIndex = index
  }

  moveFocus(direction: any, immediate?: any): boolean {
    const nextIndex = this.focusIndex + direction
    if (nextIndex < 0 || !this.itemsElement.children[nextIndex]) return false
    if (!this.itemsElement.children[nextIndex].visible)
      return this.moveFocus(direction >= 0 ? ++direction : --direction)
    this.setFocusIndex(nextIndex)
    const duration = immediate ? 0 : 0.3
    this.tag('Focus').patch({
      smooth: {
        x: [
          this.focusItem.hasRoundedFocus
            ? this.focusItem.coreX - (this.itemsElement.h - this.focusItem.width) / 2
            : this.focusItem.coreX - FOCUS_PADDING,
          { duration },
        ],
        w: [
          this.focusItem.hasRoundedFocus
            ? this.itemsElement.h
            : this.focusItem.width + FOCUS_PADDING * 2,
          { duration },
        ],
      },
    })
    this.tag('Focus').shader.radius = this.focusItem.hasRoundedFocus
      ? this.itemsElement.h / 2
      : DEFAULT_FOCUS_RADIUS

    return true
  }

  setActiveIndex(index: any) {
    this._focusIndex = index
    this._activeIndex = index
  }

  collapse(args: any) {
    if (this._getState() !== 'Collapsed') {
      this._setState('Collapsed', [args])
      return
    }
    const currentRoute = this.items[this._activeIndex]?.item?.route
    const currentSubCat = this.tag('SubCat')?.text?.text
    // Removing the following line will cause the subcategory to disappear when the user navigates to a different route
    this._onRouteInfoChange(args)
  }

  expand() {
    this._setState('Expanded')
  }

  override _getFocused() {
    return this.focusItem || this
  }

  set items(items) {
    this.itemsElement.children = items.map((item: any, index: any) => {
      return { type: MenuItem, item, index }
    })
    this.expand()
  }

  get items() {
    return this.itemsElement.children
  }

  get itemsElement() {
    return this.tag('Items')
  }

  get focusIndex() {
    return this._focusIndex
  }

  get activeIndex() {
    return this._activeIndex
  }

  get focusItem() {
    return this.itemsElement?.children[this._focusIndex]
  }

  get hasVisibleItems() {
    return this.itemsElement?.children.filter(({ visible }: any) => visible).length > 0
  }

  override _firstActive() {
    this.setActiveIndex(this.initiallySelected)
  }

  _setFocusAlpha(alpha: any) {
    this.tag('Focus').setSmooth('alpha', alpha)
  }

  override _handleRight() {
    return this.moveFocus(1)
  }
  override _handleLeft() {
    return this.moveFocus(-1)
  }

  override _handleEnter() {
    ContentPosition.clearPositionForCurrentPage()
    this.fireAncestors('$onEnterCallback')
    this.setActiveIndex(this.focusIndex)
    this.moveFocus(0, true)

    const item = this.focusItem.item
    const { route } = item
    sendMetric(EVENTS.CLICK, {
      name: item.label,
      shelf: { listTitle: 'Global Nav' },
    })
    if (item && route && route !== Router.getActiveRoute()) {
      if (route === ROUTE.live) {
        LiveStreamManager.validate()
        LiveStreamManager.setIsGlobalNavigation(true)
      }

      if (route === ROUTE.shows) {
        Router.navigate(`${ROUTE.shows}/all`, { customShelfTitle: 'Global Nav' }, item.cache)
      } else {
        Router.navigate(route, { customShelfTitle: 'Global Nav' }, item.cache)
      }
      Router.focusPage()
    }
  }

  override _focus() {
    if (this.hasVisibleItems) this._setFocusAlpha(1)
    return this.moveFocus(0, true)
  }

  override _unfocus() {
    this._setFocusAlpha(0)
  }

  _showSubCategory({ parentRoute, subCategoryItem }: any) {
    if (!subCategoryItem) return
    this.tag('SubCatHolder.Line').visible = parentRoute
    if (typeof subCategoryItem === 'string') {
      this.tag('SubCatHolder.SubCat').patch({
        text: {
          text: subCategoryItem,
          fontFace: FONT_FACE.light,
          fontSize: 35,
        },
      })
    } else if (
      subCategoryItem.type?.name === 'ScaledImageTexture' ||
      subCategoryItem.type?.name === 'ImageTexture'
    ) {
      this.tag('SubCatHolder.SubCat').patch({
        texture: subCategoryItem,
      })
    }
    this.tag('SubCatHolder').patch({
      visible: true,
    })
  }

  _hideSubCategory() {
    this.tag('SubCatHolder').patch({
      SubCat: {
        flexItem: {
          marginLeft: 20,
        },
      },
      visible: false,
    })
    this.tag('SubCatHolder').visible = false
  }

  static override _states() {
    return [
      class Expanded extends this {
        override $enter() {
          this.items.forEach((childItem: any) => {
            if (!childItem.visible) childItem.visible = true
          })
          this._hideSubCategory()
        }
      },
      class Collapsed extends this {
        override $enter(_: any, routes: any) {
          this._onRouteInfoChange(routes)
        }
        // @ts-expect-error TS(2425): Class 'ItemsBar' defines instance member property ... Remove this comment to see the full error message
        _onRouteInfoChange({ parentRoute, subCategoryItem }: any) {
          this.items.forEach((childItem: any) => {
            const isVisible = childItem.item.route === parentRoute
            if (isVisible) this.setActiveIndex(childItem.index)
            childItem.visible = isVisible
          })
          this._showSubCategory({
            parentRoute: this.hasVisibleItems,
            subCategoryItem: subCategoryItem,
          })
        }
      },
    ]
  }
}
