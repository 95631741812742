import { Lightning } from '@lightningjs/sdk'
import { COLORS } from '../../../../../constants'

export default class PeacockGradientButton extends Lightning.Component {
  unfocusBgAlpha = 1
  unfocusLabelAlpha = 1
  static override _template() {
    return {
      rect: true,
      rtt: true,
      clipping: true,
      w: 420,
      h: 50,
      color: COLORS.transparent,
      shader: {
        type: Lightning.shaders.RoundedRectangle,
        radius: 24,
      },
      FocusedBackground: {
        rect: true,
        w: (w: number) => w,
        h: (h: number) => h,
        visible: false,
        colorTop: COLORS.yellow5,
        colorBottom: COLORS.yellow4,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 22,
        },
      },
      UnfocusedBackground: {
        alpha: this.bindProp('unfocusBgAlpha'),
        texture: Lightning.Tools.getRoundRect(
          416,
          46,
          22,
          2,
          COLORS.yellow5,
          true,
          COLORS.transparent
        ),
      },
      Title: {
        x: (w: number) => w / 2,
        y: (h: number) => h / 2 + 3,
        mount: 0.5,
        text: {
          text: this.bindProp('title'),
          fontSize: 26,
          textColor: COLORS.white,
          letterSpacing: 0.8,
        },
      },
    }
  }

  private _setFocusedParams(focused: boolean) {
    this.tag('FocusedBackground').patch({
      visible: focused,
    })
    this.tag('UnfocusedBackground').patch({
      visible: !focused,
    })
    const [textColor, alpha] = focused ? [COLORS.black, 1] : [COLORS.white, this.unfocusLabelAlpha]
    this.tag('Title').patch({
      alpha,
      text: {
        textColor,
      },
    })
  }

  override _focus() {
    this._setFocusedParams(true)
  }

  override _unfocus() {
    this._setFocusedParams(false)
  }
}
