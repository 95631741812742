import Router from '@lightningjs/sdk/src/Router'
import { ScrollPosition } from '../../components/longScroll/types'

export enum ScrollElementType {
  LIST = 'list',
  GRID = 'grid',
  FEATURED = 'featured',
}
const positionDefaults: ScrollPosition = {
  type: ScrollElementType.LIST,
  row: 0,
  content: 0,
  labelIndex: 0,
}
/**
 * @Class for storing and retrieving Content Position.
 */
class ContentPositionSingleton {
  // Object containing save positions for each page:
  // { 'home' : {row: 0, content: 0}}
  _pagePositions: { [page: string]: ScrollPosition } = {}

  _getCurrentHash() {
    // Router.getActiveHash() depends on the window hashchange event
    // it's better to poll window directly
    const hash = window.location.hash.replace('#', '')
    if (!hash) {
      // LightningJS stores page hash in current page
      // wrapped in a Symbol object, to retrieve this value
      // we must use the getOwnPropertySymbols util
      const page = Router.getActivePage()
      const symbol = Object.getOwnPropertySymbols(page)[0]
      return (page as any)[symbol as any] || ''
    }
    return hash
  }

  /**
   * Reassign position.content
   * @param currentContent: number new value of content
   */
  UpdatePositionContentForCurrentPage(currentContent: number) {
    const currentPosition = this.getPositionForCurrentPage()
    if (currentPosition) currentPosition.content = currentContent
  }

  /**
   * Assign positions to the current page.
   * @param {{ type: ScrollElementType, row: number, content: number}} position
   */
  setPositionForCurrentPage(position: ScrollPosition) {
    const {
      row = positionDefaults.row,
      content = positionDefaults.content,
      labelIndex = positionDefaults.labelIndex,
      type = positionDefaults.type,
      instanceID,
      v4ID,
    } = position
    this._pagePositions[this._getCurrentHash()] = { type, row, content, instanceID, v4ID, labelIndex }
  }

  /**
   * Get the positions for the current page.
   */
  getPositionForCurrentPage(): ScrollPosition | undefined {
    return this._pagePositions?.[this._getCurrentHash()]
  }

  clearPositionForCurrentPage(waitForHashChange = false) {
    // We need to diferentiate if we need waiting for hash change or not:
    // 1. We wait for hash change: Imagine tapping a menu button,
    // we want to navigate a route but that change hasn't happened yet.
    // 2. We don't wait for hash change: This is for _handleBack events,
    // if we wait for hash change it would have the hash of the new screen.
    const handle = () => {
      const hash = this._getCurrentHash()
      if (this._pagePositions?.[hash]) {
        delete this._pagePositions[hash]
      }
      if (waitForHashChange) {
        window.removeEventListener('hashchange', handle)
      }
    }
    if (waitForHashChange) {
      window.addEventListener('hashchange', handle)
    } else {
      handle()
    }
  }
}

const ContentPosition = new ContentPositionSingleton()
export default ContentPosition
