import Announce from './Announce'

export default class BaseAnnounce extends Announce {
  private _utterance!: SpeechSynthesisUtterance
  private _suppressTimeout: number | null = null

  async speak(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._utterance = new SpeechSynthesisUtterance(this._phrase)
      this._utterance.onend = (): void => {
        this._clearSuppressTimeout()
        resolve()
      }
      this._utterance.onerror = (e): void => {
        // Unknown, cancel or interrupt error (ignore)
        this._clearSuppressTimeout()
        if (e.error && !['canceled', 'interrupted'].includes(e.error)) {
          reject(e)
          return
        }
        resolve()
      }
      window.speechSynthesis.speak(this._utterance)
      window.speechSynthesis.resume()
      const suppressPromise = () => {
        if (!window.speechSynthesis.speaking) {
          this._suppressTimeout = null
          resolve();
          return;
        }
        this._suppressTimeout = window.setTimeout(suppressPromise, 1000);
      }
      suppressPromise();
    })
  }

  private _clearSuppressTimeout(): void {
    if (this._suppressTimeout) window.clearTimeout(this._suppressTimeout)
    this._suppressTimeout = null
  }
}
