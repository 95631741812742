import { Router } from '@lightningjs/sdk'
import AppConfigFactorySingleton from '../../../config/AppConfigFactory'
import { PlayerStates, ROUTE, ROUTE_ENTITY, STORAGE_KEYS } from '../../../constants'
import RouterUtil from '../../../util/RouterUtil'
import { CCTypes, ClosedCaptionsUtils } from '../../../lib/ClosedCaptions/ClosedCaptionsUtils'
import { LiveStreamManager } from '../../../lib/LiveStreamManager'
import { navigateLinearItem } from '../../../components/items/helpers/linear'
import { usePreCheckData } from '../../../store/utils/preCheckData'
import { openPlayerLoader } from '../../../widgets/Modals/playerLoader/PlayerLoader'

export const EpgStateFactory = (base: any) =>
  class Epg extends base {
    _epgOpened: boolean

    async $enter({ prevState }: any, isError: boolean) {
      this._preState = prevState
      if (!this._epg) return
      this._epg.isError = isError
      if (Router.getActiveRoute() === ROUTE.live) {
        LiveStreamManager.update().finally(() => {
          this._showEpg()
        })
      } else {
        this._showEpg()
      }
    }

    _showEpg() {
      this._epg.sync()
      this._epg.load()
      this._epg.visible = true
      this.widgets.menu.visible = true
      this._epg.alpha = 1
      this._player?.enableSubtitles(CCTypes.off)
      this._epgOpened = true
    }

    $exit() {
      this.widgets.menu.visible = false
      this._epg.alpha = 0
      this._player?.enableSubtitles(ClosedCaptionsUtils.getCCType())
    }

    _handleUp() {
      Router.focusWidget('Menu')
    }

    _handleBack(e: any) {
      if (this._preState === 'LPPlayer.Controls' && !this._hasError) {
        this.$showPlayer()
      } else {
        this._closeMediaPlayer()
      }
      e.preventDefault()
      e.stopPropagation()
    }

    _getFocused() {
      return this.tag('Epg') || this
    }

    $showPlayer() {
      this._setState(PlayerStates.LPPlayer)
    }

    async $streamSelected(selectedStream: any, selectedProgram: any) {
      openPlayerLoader(selectedStream, selectedProgram)
      const { pid } = selectedStream
      if (pid) {
        RouterUtil.navigateToRoute(ROUTE.watch, {
          entity: ROUTE_ENTITY.pid,
          value: pid,
        })
        return
      }
      if (AppConfigFactorySingleton.config.live?.savePreviousSelection?.enabled) {
        this._storage.set(STORAGE_KEYS.PREVIOUS_STREAM, selectedStream.streamAccessName)
      }

      await navigateLinearItem({ stream: selectedProgram, routerBackDisabled: true })
        .then(() => {
          if (Router.getActiveRoute() !== ROUTE.live) {
            // Closing player before change route because the attach event for the new route is
            // called before the detach event from the previous route, it generates issues with the player events emitters
            this._closePlayer()
            Router.navigate(ROUTE.live)
          } else {
            usePreCheckData()
          }
        })
        .catch((error) => {
          // fail silently
        })
    }
  }
