import Announce from './Announces/Announce'
import TVPlatform from '../tv-platform'
import { AnnouncementType } from './types'
import { splitTextToFitLength } from '../../helpers'

class SpeechController {
  private _active = false
  private _voiceOutDisabled = false
  private _announces: Announce[] = []
  private _tts = TVPlatform.tts()
  private _lastPhrase!: string

  get active(): boolean {
    return this._active
  }

  get voiceOutDisabled(): boolean {
    return this._voiceOutDisabled
  }

  append(toSpeak: AnnouncementType[], notification = false): void {
    const seriesArray = toSpeak.flatMap((announce) => {
      return (this._normalize(Array.isArray(announce) ? announce : [announce]))
        .filter((item) => item.length)
    }).filter((item) => !!item && item.length)

    const inlineText = seriesArray.join('\n\n')
    if (!inlineText.length) return

    if (this._tts.chunkLength && inlineText.length <= this._tts.chunkLength) {
      this._announces.push(this._tts.speak(inlineText, notification))
      return
    }
    for (const phrase of seriesArray) {
      this._announces.push(this._tts.speak(phrase, notification))
    }
  }

  cancel(): void {
    this._announces = []
    this._tts.cancel()
    this._active = false
    this._voiceOutDisabled = false
  }

  async speak(series: AnnouncementType[], notification = false): Promise<void> {
    this._active = true
    this.append(series, notification)
    await this._speakSeries()
  }

  private async _speakSeries(): Promise<void> {
    let series: Announce | undefined
    while (this._announces.length) {
      series = this._announces.shift()
      if (!series) continue
      this._voiceOutDisabled = series.notification
      this._lastPhrase = series.phrase
        await series.speak()
    }
    if (!series || this._lastPhrase === series.phrase) {
      this._voiceOutDisabled = false
      this._active = false
    }
  }

  private _normalize(phrasesArray: Array<AnnouncementType>): Array<string> {
    let normalizedPhrasesArray: string[] = []
    for (const string of phrasesArray) {
      if (Array.isArray(string)) {
        normalizedPhrasesArray.push(this._normalize(string).join(' '))
      } else {
        const phrase = typeof string === 'function' ? string() : string
        const result = this._tts.chunkLength
          ? splitTextToFitLength(phrase, this._tts.chunkLength)
          : phrase
        if (Array.isArray(result)) {
          normalizedPhrasesArray.push(...result)
        } else {
          normalizedPhrasesArray.push(result)
        }
      }
    }
    return normalizedPhrasesArray
  }
}

export default new SpeechController()
