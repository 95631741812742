import { Storage } from '@lightningjs/sdk'
import { CONTENT_TYPE, STORAGE_KEYS } from '../../../../constants'
import { getAdjustedReferringShelf, getDurationSinceLaunch } from '../../../../helpers'
import { EVENTS } from '../../../../lib/analytics/types'
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore'
import RouterUtil from '../../../../util/RouterUtil'
import { sendMetric } from '../../../../lib/analytics/Analytics'
import AuthenticationSingleton from '../../../../authentication/Authentication'
import { ClosedCaptionsUtils } from '../../../../lib/ClosedCaptions/ClosedCaptionsUtils'
import { useProgress } from '../../../../components/player/PlayerControls/hooks/useProgress'
import BasePlayer from '../../BasePlayer'
import { LiveStreamManager } from '../../../../lib/LiveStreamManager'

export class AnalyticsDelegate<T extends BasePlayer> {
  _ctx: T
  _player: any
  _stream_type: any

  constructor(ctx: T) {
    this._ctx = ctx
  }

  sendMetric(name: any, payload: any) {
    return sendMetric(name, payload)
  }

  _getAnalyticsData() {
    return {}
  }

  _getMetricsData() {
    const { stream, program, lemonade, geo } = PlayerStoreSingleton
    if (!program || !stream) return {}
    const storageReferringShelf = Storage.get(STORAGE_KEYS.REFERRING_SHELF)
    const smartTile = Storage.get(STORAGE_KEYS.SMART_TILE)
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    const referringShelf = getAdjustedReferringShelf(storageReferringShelf ?? {})
    const pid = lemonade?.pid || ('pid' in stream && stream?.pid) || ''
    const isSle = this._ctx._stream_type === CONTENT_TYPE.SLE
    const duration =
      isSle || !('duration' in program && typeof program.duration === 'number')
        ? 0
        : program?.duration
    const durationInMilliseconds =
      'durationInMilliseconds' in program && typeof program.durationInMilliseconds === 'number'
        ? program?.durationInMilliseconds
        : duration * 1000
    const durationSinceLaunch =
      this._ctx._stream_type === CONTENT_TYPE.FER && getDurationSinceLaunch()
    const progress = useProgress().value?.[0]
    const liveWatchProgressMs = LiveStreamManager.startTime
      ? new Date().getTime() - LiveStreamManager.startTime
      : 0

    return {
      ...program,
      ...geo,
      ...stream,
      pid,
      path: RouterUtil.current(),
      streamUrl: lemonade?.playbackUrl || lemonade?.playbackUrls || '',
      ccLanguage: ClosedCaptionsUtils.getCCType(),
      ...(this._ctx._programOrder ? { programOrder: this._ctx._programOrder } : {}),
      ...(progress ? { linearDuration: progress, watched: progress } : {}),
      shelf: referringShelf,
      duration,
      durationInMilliseconds,
      resumeTime: 0,
      durationSinceLaunch,
      smartTile,
      authType: this._ctx.authtype,
      liveWatchProgress:
        liveWatchProgressMs && !isNaN(liveWatchProgressMs) ? liveWatchProgressMs / 1000 : 0,
      initialTempPassRequest: this._ctx.initialTempPassRequest,
    }
  }

  getErrorAnalytics(error: any, errorEvent: any) {
    const mvpd = AuthenticationSingleton.getMvpdData()?.mvpd
    const code = errorEvent?.code || errorEvent?.type
    const description = errorEvent?.description
    const err = new Error()
    return {
      player: this._player,
      video: this._getMetricsData(),
      errorType: 'Video',
      description,
      friendlyMessage: error.message,
      code,
      mvpd: mvpd,
      source: this._stream_type,
      system: 'Other',
      stack: err.stack,
      fatal: true,
      isLive: true,
    }
  }

  firePageLoad() {
    sendMetric(EVENTS.PAGE_LOAD, this._getMetricsData())
  }

  firePlayerReady() {
    sendMetric(EVENTS.PLAYER_READY)
  }

  fireClick(name: any) {
    const { stream, program } = PlayerStoreSingleton
    if (!stream || !program) return
    const brand = 'brand' in program ? program.brand?.title : stream?.brandDisplayTitle
    const show =
      ('series' in program && program.series) ||
      ('seriesShortTitle' in stream && stream.seriesShortTitle) ||
      ''
    const season = 'seasonNumber' in stream ? stream.seasonNumber : ''
    const video = 'mpxGuid' in stream ? stream.mpxGuid : ''
    sendMetric(EVENTS.CLICK, {
      name,
      brand,
      show,
      season,
      video,
      shelfTitle: getAdjustedReferringShelf()?.listTitle,
    })
  }

  fireContentClick(payload: any) {
    sendMetric(EVENTS.CONTENT_CLICK, payload)
  }

  fireSessionInit() {
    sendMetric(EVENTS.LIVE_SESSION_INITIALIZED)
  }

  fireSessionStart() {
    LiveStreamManager.setStartTime()
    sendMetric(EVENTS.LIVE_SESSION_START, this._getMetricsData())
  }

  fireSessionEnd() {
    sendMetric(EVENTS.LIVE_SESSION_END, this._getMetricsData())
    LiveStreamManager.resetStartTime()
  }

  fireSeekStart() {
    sendMetric(EVENTS.SEEK_START)
  }

  fireSeekEnd() {
    sendMetric(EVENTS.SEEK_END)
  }

  fireVideoStart(data = {}) {
    sendMetric(EVENTS.VOD_SESSION_START, {
      ...this._getMetricsData(),
      ...data,
    })
  }

  fireVideoEnd(data = {}) {
    sendMetric(EVENTS.VOD_SESSION_END, {
      ...this._getMetricsData(),
      ...data,
    })
  }
}
