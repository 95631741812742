import Collection from './Collection'
import componentTypes from '../../types/componentTypes'
import { Container } from '../index'

export default class LinksSelectableGroup extends Collection {
  get itemLabels() {
    return this.obj.itemLabels
  }

  get itemLabelsTitle() {
    return this.obj.itemLabelsTitle || this.obj.optionalTitle
  }

  get itemLabelsConfig() {
    return this.obj.itemLabelsConfig
  }

  override mapItems(items: any) {
    return items
      .filter((item: any) => item && Object.values(componentTypes).indexOf(item.component) > -1)
      .map((item: any) => new Container(item, this.index))
  }
}
