import { Language, Lightning } from '@lightningjs/sdk'

import Loader from '../loader/Loader'
import LabelsList, { LabelsListRenderingType } from '../labelsList/LabelsList'

import { setSmooth } from '../../helpers'
import { sendMetric } from '../../lib/analytics/Analytics'
import { EVENTS } from '../../lib/analytics/types'
import { COLORS, ENTITY_TYPES, FONT_FACE, TEXT_ALIGN, VERTICAL_ALIGN } from '../../constants'
import { ScrollPosition } from '../longScroll/types'

export default class LinksSelectableGroup extends Lightning.Component {
  _dataItemsAvailable: any
  _initialFocusState: any
  _itemLablesCount: any
  _selectedIndex: any
  _spawner: any
  _labelsList: LabelsList
  _cursor!: string

  static override _template() {
    return {
      Title: {
        alpha: 0,
        y: 25,
        mountY: 0.5,
        text: {
          textColor: COLORS.white,
          verticalAlign: VERTICAL_ALIGN.middle,
          textAlign: TEXT_ALIGN.center,
          fontSize: 40,
          fontFace: FONT_FACE.light,
        },
      },
      LabelsList: {
        type: LabelsList,
        mode: LabelsListRenderingType.HORIZONTAL,
        x: 922.5,
        mountX: 0.5,
      },
      Data: {},
      ErrorMessage: {
        alpha: 0,
        x: 960,
        mountX: 0.5,
        y: 264,
        text: {
          textColor: COLORS.white,
          verticalAlign: VERTICAL_ALIGN.middle,
          textAlign: TEXT_ALIGN.center,
          fontSize: 48,
          fontFace: FONT_FACE.light,
          text: Language.translate('noContentAvailable'),
        },
      },
      Loader: {
        alpha: 10,
        type: Loader,
        y: 264,
      },
    }
  }

  override _build() {
    this._labelsList = this.tag('LabelsList')
  }

  get defaultState(): string {
    return this._dataItemsAvailable ? 'Data' : ''
  }

  get initialFocusState() {
    return this._initialFocusState ? this._initialFocusState : this.defaultState
  }

  set initialFocusState(focusState) {
    this._initialFocusState = focusState
  }

  set spawner(v: any) {
    this._spawner = v
  }

  get spawner() {
    return this._spawner
  }

  set data(v: any) {
    this._selectedIndex = this._spawner.initiallySelected
    this.title = this._spawner.itemLabelsTitle
    this.setLabelsList(this._spawner.createItemLabels(v.obj?.fontSize), this._selectedIndex)
    this.loadDataItems(this._spawner.initialItemHandle).then(() => this._setState(this.initialFocusState))
  }

  async loadDataItems(initialItemHandle?: string) {
    setSmooth(this.tag('Loader'), 'alpha', 1)
    setSmooth(this.tag('Data'), 'alpha', 0)
    setSmooth(this.tag('ErrorMessage'), 'alpha', 0)
    this.dataItems = await this._spawner.createData(this.stage, this._selectedIndex, initialItemHandle)
  }

  getDataElement() {
    return this.tag('Data')
  }

  set title(v: any) {
    if (v) {
      this.patch({
        Title: {
          alpha: 1,
          text: { text: v },
        },
      })

      this.tag('Title').loadTexture()
      const titleWidth = this.tag('Title').finalW

      this.patch({
        LabelsList: {
          x: titleWidth + 47,
          mountX: 0,
        },
      })
    } else {
      this.patch({
        Title: {
          alpha: 0,
        },
        LabelsList: {
          x: 922.5,
          mountX: 0.5,
        },
      })
    }
  }

  setLabelsList(itemsLabelsList: any, selectedIndex: any) {
    this._itemLablesCount = itemsLabelsList.length
    if (this._labelsList) {
      this._labelsList.items = itemsLabelsList
      this._labelsList.activeIndex = selectedIndex
    }
  }

  set dataItems(v: any) {
    if (v) {
      this._dataItemsAvailable = true
      v.y = 100
      this.patch({
        Data: v,
      })
      setSmooth(this.tag('Data'), 'alpha', 1)
    } else {
      this._dataItemsAvailable = false
      setSmooth(this.tag('ErrorMessage'), 'alpha', 1)
    }
    setSmooth(this.tag('Loader'), 'alpha', 0)
  }

  showItemLabels(v: any) {
    setSmooth(this._labelsList, 'alpha', v, {
      duration: 0.3,
      delay: 0,
    })
  }

  override _focus() {
    // We only need to update the init focus state when
    // no prev state has been set
    if (!this.state) {
      this._setState(this.initialFocusState)
    }
  }

  /**
   * Toggle display (opacity) of title
   *
   * When this lists slides, currently only SNL seasons,
   * This is required so season nums don't collide with title
   * as they scroll out. When seasons scroll back, show it again.
   *
   * @param {number} v alpha value (1,0) to show hide
   */
  $toggleTitleSeason(v: any) {
    this.tag('Title').setSmooth('alpha', v, { duration: 0.3 })
  }

  $getMoreItems(queryVariables: any) {
    if (this._cursor === queryVariables.cursor) return
    this._cursor = queryVariables.cursor
    this._spawner.getMoreItems(queryVariables).then(({ items, moreItems }: any) => {
      this.tag('Data').moreItems = moreItems
      this.tag('Data').addItems(items)
    }).catch(() => {
      // fail silently
    })
  }

  $getCurrentScrollPosition() {
    const position = { ...this.fireAncestors('$getCurrentScrollPosition') } as ScrollPosition
    if (!position.labelIndex) position.labelIndex = this._labelsList.activeIndex
    return position
  }

  static override _states() {
    return [
      class LabelsList extends this {
        override _dataItemsAvailable: any
        override _selectedIndex: any
        override _spawner: any
        override _handleDown() {
          if (this._dataItemsAvailable) {
            this._setState('Data')
          }
        }
        override _getFocused() {
          return this._labelsList || this
        }
        trackContentClick() {
          const params = {
            entity: { analytics: {}, entityType: ENTITY_TYPES.BRAND },
            shelf: {
              position: this._selectedIndex + 1,
              customPosition: 1,
              analytics: {},
            },
          }
          sendMetric(EVENTS.CONTENT_CLICK, params)
        }
        override _handleEnter() {
          this.trackContentClick()
          // Store So we can use it later.
          const prevIndex = this._selectedIndex
          this._selectedIndex = this._labelsList?.focusIndex

          this.fireAncestors('$selectedLinkChanged', {
            prev: this._spawner.getLabelByIndex(prevIndex),
            new: this._spawner.getLabelByIndex(this._selectedIndex),
          })
          this.loadDataItems()
        }
      },
      class Data extends this {
        override _itemLablesCount: any
        override $enter() {
          this.fireAncestors('$focusedData', true)
        }
        override $exit() {
          this.fireAncestors('$focusedData', false)
        }
        override _handleUp() {
          if (this._itemLablesCount === 1) {
            return false
          } else {
            this._setState('LabelsList')
          }
        }
        override _getFocused() {
          return this.tag('Data') || this
        }
      },
    ]
  }
}
