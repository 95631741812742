import { collectionComponents, createItems } from '../../components/componentMaps'
import { lowercaseFirst } from '../../helpers'
import { scrollFromSeason } from '../requests'
import Container from '../models/Container'
import Variables from '../../graphql/Variables'
import { ContinuousScrollSpawner } from './index'
import { useRequest } from '../../lib/useRequest'
import { PaginatedComponentRequestConfig } from '../../components/LinksSelectableGroup/request'

export default class GroupedContinuousScroll {
  _groupContinuousScroll: any
  _urlAlias: any
  constructor(groupContinuousScroll: any, urlAlias: any) {
    this._groupContinuousScroll = groupContinuousScroll
    this._urlAlias = urlAlias
  }

  createItemLabels(fontSize = null) {
    const activeIndex = this.initiallySelected
    return this._groupContinuousScroll.data.groups.map((item: any, index: any) => {
      return {
        label: item.title,
        active: index === activeIndex,
        fontSize,
        ariaLabel: this._groupContinuousScroll.data.itemLabels?.[index],
      }
    })
  }

  async createData(stage: any, index: any, initialItemHandle?: string) {
    let dataComponent = this._groupContinuousScroll.data.itemsComponentType
    let dataItem = this._groupContinuousScroll.data
    if (!index && this.initiallySelected) {
      index = this.initiallySelected
    }
    const groupItem = dataItem.groups[index]

    if (groupItem && groupItem.id) {
      const queryData = await scrollFromSeason(
        Variables.getScrollFromSeasonVariables(groupItem.id, this._urlAlias)
      )
      if (queryData && queryData.data) {
        const containerData = new Container(queryData.data, index)
        this._groupContinuousScroll = containerData
        dataComponent = containerData.data?.itemsComponentType
        dataItem = containerData.data?.continuousScroll
      } else {
        return
      }
    }

    if (dataItem && dataItem.items) {
      dataItem.activeIndex = index
      return stage.c({
        type: collectionComponents.get(dataComponent),
        tag: `${lowercaseFirst(dataItem.items[0].component)}`,
        items: createItems(dataItem.items),
        groupIds: this._groupContinuousScroll.data.continuousScroll.groupIds,
        initialItemHandle,
        initialItem: dataItem.initialItem,
        title: '', //Title isn't required for shelf component if it is child component of LinkSelectable Group
        next: this._groupContinuousScroll.data.continuousScroll.next,
        previous: this._groupContinuousScroll.data.continuousScroll.previous,
        spawner: new ContinuousScrollSpawner(
          this._groupContinuousScroll.data.continuousScroll,
          stage.urlAlias
        ),
      })
    }
  }

  async getMoreItems(queryVariables: any) {
    const moreItemsData = await useRequest(PaginatedComponentRequestConfig(queryVariables)).fetch()
    const { data = {} } = new Container(moreItemsData?.data)
    return {
      items: createItems(data?.items),
      moreItems: data?.moreItems,
    }
  }

  get initiallySelected() {
    return this._groupContinuousScroll.data.initiallySelected || 0
  }

  get initialItemHandle() {
    return this._groupContinuousScroll.data.initialItemHandle
  }

  get itemLabelsTitle() {
    return this._groupContinuousScroll.title
  }

  getLabelByIndex(index: any) {
    return this._groupContinuousScroll.data.groups[index].title
  }
}
